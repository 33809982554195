<template>
  <div class="w-1200">
  <div class="list-page clear">
    <div class="left">
      <h3>
        应聘方式:请将个人简历发送至HR邮箱:
        <em>hjdhr@gemdale.com</em>
      </h3>
      <h3>关注微信公众号 “<em>金地校招</em>” 、“<em>弘金地体育</em>”以及“<em>弘金地招聘</em>”，关注最新招聘动态和岗位需求，找到自己喜欢的岗位，进行投递吧</h3>
      <div class="search-box clear">
        <div class="search-input">
          <el-input placeholder="岗位搜索" v-model="searchValue">
            <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
          </el-input>
        </div>
        <div class="city-list">
          <el-select v-model="city" placeholder="活动区域">
            <el-option v-for="(city,idx) in cityList" :key="idx"
                       :label="city" :value="city"></el-option>
          </el-select>
        </div>
      </div>

    </div>
    <div class="right clear" @mouseleave="detailLeave">
      <div class="right-content clear" @mouseleave="detailLeave">
        <!-- <div class="empty-box" v-if="list.length===0">
          <img src="../../assets/img/hire.jpg" alt="">
          <img src="../../assets/img/2021/recruit_empty@2x.png" alt="">
          <p>暂无内容</p>
        </div> -->
        <img style="margin-bottom:10px" src="../../assets/img/hire.jpg" alt="">
        <div class="list-item"
             v-if="list.length"
             @mouseenter.stop="hoverAction(idx)"
             @mouseleave.stop="outerAction(idx)"
             @click="mIdx!=idx?mIdx=idx:mIdx=-1"
             :class="{active:hoverIdx===idx,'m-active':mIdx===idx}"
             v-for="(item,idx) in 2" :key="idx">
          <div class="box">
            <img src="../../assets/img/2021/recruit_image.png" alt="">
            <div class="box-text">
              <h3>{{ item.positionName }}</h3>
              <p class="diandian">招聘公司：{{ item.company }} </p>
              <p class="diandian">工作地点：{{ item.workAddress }}</p>
              <p class="diandian">部门：{{ item.department }}</p>
              <p class="diandian">招聘对象：{{ item.objectType==0?'全职': item.objectType==1?'兼职':'其他'}} 招聘人数：{{ item.num }}</p>
            </div>
          </div>
          <div class="box-detail" v-if="mIdx===idx">
            <div v-html="item.content"></div>
            <div class="email">应聘方式：请将个人简历发送至HR邮箱：<em>liuxu2@gemdal.com</em></div>
          </div>
          <div v-if="hoverIdx===idx" class="active-bg animate__animated "
          :class="{'animate__bounceInRight':idx%2===1,'animate__bounceInLeft':idx%2===0}"></div>
        </div>
        <div class="detail-box animate__animated"
             :class="{'animate__bounceInRight':hoverIdx%2===1,'animate__bounceInLeft':hoverIdx%2===0}"
             :style="detailStyle"
             v-if="hoverIdx!==-1" >
          <div class="content" @mouseenter.stop="detailHover" @mouseleave="detailLeave">
            <div v-html="list[hoverIdx].content"></div>
          </div>
        </div>
      </div>
      <div class="pageDiv" v-if="list.length!=0">
        <el-pagination :page-size="page.pageSize" :current-page.sync="page.pageNum" layout="prev, pager, next"
                       :total="total" @current-change="pageChange"></el-pagination>
      </div>
      <div class="pageDiv-2" v-if="list.length!=0">
        <h5>总共{{Math.ceil(total/page.pageSize)}}页，当前在{{page.pageNum}}/{{Math.ceil(total/page.pageSize)}}页</h5>
        <h5>
          <button @click="pageChange('show')">首页</button>
          <button @click="pageChange('prev')">上一页</button>
          <button @click="pageChange('next')">下一页</button>
          <button @click="pageChange('wei')">尾页</button>
        </h5>
      </div>
      <h4 v-show="list.length>0" class="back-top" @click="toTop"><span>回到顶部</span><em></em></h4>
    </div>
  </div>
  </div>
</template>

<script>
import { RecruitList } from '@/api/index.js'

export default {
  data(){
    return{
      type:0,
      searchValue:"",
      city:'深圳',
      cityList:['深圳','北京','上海','东莞','南京','武汉','大连'],
      page: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      list: [],
      hoverIdx:-1,
      inter:null,
      mIdx:-1
    }
  },
  created() {
    //type 0社会招聘 1校园招聘
    this.init()
  },
  computed:{
    detailStyle(){
      let idx=Math.floor(this.hoverIdx/2)
      if(this.hoverIdx<6){
        return {
          top:(idx + 1) * 196 + 10 * idx +'px'
        }
      }else{
        idx=Math.floor((9-this.hoverIdx)/2)
        return {
          bottom:(idx + 1) * 196 + 10 * (idx+1)+ 150 +'px'
        }
      }

    }
  },
  watch:{
    $route(newVal) {
      let newType=newVal.params.type
      if(newType==='school'||newType==='social'){
        if(newType==='school'){
          this.type=1
        }else if(newType==='social'){
          this.type=0
        }
        this.init()
      }
    },
    city(){
      this.init()
    }
  },
  methods:{
    toTop() {
      window.scrollTo(0, 0);
    },
    init(){
      this.page= {
        pageSize: 10,
        pageNum: 1
      }
      this.total= 0
      this.hoverIdx=-1
      this.inter=null
      this.getData()
    },
    getData(){
      RecruitList({
        city:this.city,
        searchValue:this.searchValue,
        type:this.type,
        ...this.page
      }).then(res=>{
        this.list= []
        if(res){
          this.list=res.rows
          this.total=res.total
        }
      })
    },
    hoverAction(idx){
      clearTimeout(this.inter)
      this.inter=null
      if(this.hoverIdx===idx) return false;
      this.hoverIdx=-1
      let _this=this
      this.inter=setTimeout(()=>{
        _this.hoverIdx=idx
        clearTimeout(_this.inter)
        _this.inter=null
      },300)
    },
    outerAction(idx){
      let _this=this
      this.inter=setTimeout(()=>{
        _this.hoverIdx=-1
        clearTimeout(_this.inter)
        _this.inter=null
      },300)
    },
    detailHover(){
      clearTimeout(this.inter)
      this.inter=null
    },
    detailLeave(){
      let _this=this
      clearTimeout(_this.inter)
      _this.inter=null
      this.inter=setTimeout(()=>{
        _this.hoverIdx=-1
        clearTimeout(_this.inter)
        _this.inter=null
      },300)
    },
    pageChange(val) {
      if(val=='show'){
        if(this.page.pageNum==1) return false;
        val = 1;
      }
      if(val=='prev'){
        if(this.page.pageNum==1) return false;
        val = this.page.pageNum-1
      }
      if(val=='next'){
        if(this.page.pageNum==Math.ceil(this.total/this.page.pageSize)) return false;
        val = this.page.pageNum+1
      }
      if(val=='wei'){
        if(this.page.pageNum==Math.ceil(this.total/this.page.pageSize)) return false;
        val = Math.ceil(this.total/this.page.pageSize)
      }
      this.page.pageNum = val
      this.hoverIdx=-1
      this.mIdx=-1
      this.inter=null
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
em{
  font-style: normal;
}
.back-top,.pageDiv-2 {
  display: none;
}
.empty-box{
  img{
    width: 360px;
    margin: 100px auto 30px;
  }
  p{
    text-align: center;
    margin-bottom: 200px;
    color: #6e6e6e;
    font-size: 14px;
  }
}
.list-page{
  >div{
    height: 100%;
    box-sizing: border-box;
  }
  .left{
    width: 25%;
    float: left;
    padding-right: 24px;
    h3{
      font-size: 13px;
      color: #333333;
      line-height: 21px;
      letter-spacing: 1px;
      padding-top: 40px;
      em{
        color: #EA5532;
      }
    }
    .search-box{
      margin-top: 77px;
      padding-left: 50px;
      padding-right: 20px;
      .search-input{
        margin-bottom: 22px;
      }
    }
  }
  .right{
    width: 75%;
    min-height: 500px;
    float: left;
    padding: 40px 40px 0;
    border-left: 1px solid #dcdcdc;
    position: relative;
    .detail-box {
      position: absolute;
      left: 0;
      width: 100%;
      height: auto;
      padding:40px 40px;
      box-sizing: border-box;
      transition: all .3s;
      z-index: 2;
      .content{
        width: 100%;
        padding: 30px 40px;
        background-color: #fff;
        border: 2px solid #EA5532;
        box-sizing: border-box;
      }
    }
    .list-item{
      width: 49.5%;
      float: left;
      height: 196px;
      background: #eeeeee;
      color: #333333;
      line-height: 25px;
      font-size: 13px;
      box-sizing: border-box;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      transition: all 0.3s .3s;
      &:nth-of-type(2n-1){
        margin-right: 1%;
      }
      &.active{
        color: #fff;
      }
      .box-detail{
        display: none;
      }
      .box{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 34px 10px 34px 30px;
        position: absolute;
        background-color: transparent;
        z-index: 2;
      }
      .active-bg{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #EA5532;
        z-index: 1;
      }

      img{
        width: 107px;
        height: 125px;
        float: left;
        margin-right: 26px;
      }
      h3{
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 10px;
      }
      p{
        font-size: 13px;
        line-height: 1.7;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .empty-box{
    img{
      width: 180px;
      margin: 0 auto;
    }
    p{
      text-align: center;
      margin-bottom:100px;
      color: #6e6e6e;
      font-size: 12px;
    }
  }
  .back-top {
    display: block;
    background-color: #b4b4b4;
    line-height: 48px;
    height: 48px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    position: relative;
    span {
      display: inline-block;
    }
    em {
      display: inline-block;
      width: 18px;
      height: 14px;
      background-image: url("../../assets/img/2021/up.png");
      background-size: 100% 100%;
      margin-left: 7px;
      transition: all 0.3s;
      transform-origin: 50% 50%;
    }
  }
  .pageDiv{
    display: none;
  }
  .pageDiv-2{
    display: block;
    text-align: center;
    color: #000;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 15px;
    button{
      display: inline-block;
      background-color: #787878;
      color: #fff;
      padding:5px 8px;
      margin:10px 4px;
      width: 56px;
    }
  }

  .left h3{
    display: none;
  }
  .left{
    width: 100%!important;
    padding-right: 0!important;
    .search-box{
      margin-top: 40px!important;
      padding: 0 20px!important;
      .search-input,.city-list{
        width: 48%;
        float: left;
      }
      .search-input{
        margin-right: 4%;
      }
    }
  }
  .list-page .right{
    min-height:calc(100vh - 380px);
    width: 100%!important;
    padding:20px 20px 50px 20px!important;
    border: none!important;
    .detail-box{
      display: none!important;
    }
    .active-bg{
      display: none!important;
    }
    .list-item{
      height: auto!important;
      width: 100%!important;
      color: #333333!important;
      transition: none!important;
      &.m-active{
        background-color: #ea5431;
        color: #fff!important;
        margin-bottom: 0!important;
      }
      .box{
        position: relative;
        padding: 15px;
        img{
          width: 84px;
          height: 98px;
        }
        h3{
          font-size: 13px;
          margin-bottom: 0;
        }
        p{
          font-size: 12px;
        }
      }
      .box-detail{
        display: block!important;
        background-color: #fff;
        border: 1px solid #EA5532;
        padding: 15px;
        .email{
          font-size: 10px;
          color: #333333;
          margin-top: 10px;
          em{
            color: #ea5431;
          }
        }
      }
    }
  }
}
</style>
